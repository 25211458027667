<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MINIMUM WAGES PER REGION</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              Region #
            </th>
            <th class="text-uppercase">
              Region
            </th>
            <th class="text-uppercase">
              Minimum Wages
            </th>
            <th class="text-uppercase">
              Effective Date
            </th>
            <th class="text-uppercase">
              RA Minimum Wages
            </th>
            <th class="text-uppercase">
              RAO Minimum Wages
            </th>
            <th class="text-uppercase">
              BM Minimum Wages
            </th>
            <th class="text-uppercase">
              RM Minimum Wages
            </th>
            <th class="text-uppercase">
              AVP/Territory Heads Minimum Wages
            </th>
            <th class="text-uppercase">
              VP Minimum Wages
            </th>
            <th class="text-uppercase">
              CM Minimum Wages
            </th>
            <th class="text-uppercase">
              Embalmer Minimum Wages
            </th>
            <th class="text-uppercase">
              COOP BM Minimum Wages
            </th>
            <th class="text-uppercase">
              COOP GM Minimum Wages
            </th>
            <th class="text-uppercase">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in active_items"
            :key="item.id"
          >
            <td>
              {{ item.region_no }}
            </td>
            <td>
              {{ item.region }}
            </td>
            <td>
              {{ formatPrice(item.minimum_wage) }}
            </td>
            <td>
              {{ item.effective_date }}
            </td>
            <td>{{ formatPrice(item.ra_minimum_wage) }}</td>
            <td>{{ formatPrice(item.rao_minimum_wage) }}</td>
            <td>{{ formatPrice(item.bm_minimum_wage) }}</td>
            <td>{{ formatPrice(item.rm_minimum_wage) }}</td>
            <td>{{ formatPrice(item.avp_minimum_wage) }}</td>
            <td>{{ formatPrice(item.vp_minimum_wage) }}</td>
            <td>{{ formatPrice(item.cm_minimum_wage) }}</td>
            <td>{{ formatPrice(item.embalmer_minimum_wage) }}</td>
            <td>{{ formatPrice(item.coop_bm_minimum_wage) }}</td>
            <td>{{ formatPrice(item.coop_gm_minimum_wage) }}</td>
            <td class="text-center">
              <v-icon
                v-if="!is_editing"
                class="mr-2"
                color="info"
                @click="edit_wages(item)"
              >
                {{ icons.mdiPencil }}
              </v-icon>
              <v-progress-circular
                color="info"
                indeterminate
                v-if="is_editing"
              ></v-progress-circular>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog persistent width="500px" v-model="is_editing">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">EDIT MINIMUM WAGES in
            {{selected_region.region}}</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-6">
          <v-text-field
            class="mx-2 mb-4"
            v-model="wages"
            dense
            label="Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="ra_minimum_wage"
            dense
            label="RA Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="rao_minimum_wage"
            dense
            label="RAO Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="bm_minimum_wage"
            dense
            label="BM Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="rm_minimum_wage"
            dense
            label="RM Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="avp_minimum_wage"
            dense
            label="AVP/Territory Heads Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="vp_minimum_wage"
            dense
            label="VP Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="cm_minimum_wage"
            dense
            label="CM Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="embalmer_minimum_wage"
            dense
            label="Embalmer Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="coop_bm_minimum_wage"
            dense
            label="COOP BM Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2 mb-4"
            v-model="coop_gm_minimum_wage"
            dense
            label="COOP GM Minimum Wages"
            type="number"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
          <v-text-field
            class="mx-2"
            v-model="wages_effective"
            dense
            label="Effective Date"
            type="date"
            :rules="rules.default_max_45_character_and_no_empty_rule"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" @click="save_edit">
            Save Changes
          </v-btn>
          <v-btn color="error" @click="is_editing=false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {
    mdiCheck
    , mdiClose
    , mdiPencil
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      saving2: false,
      alert2: false,
      alert_message2: '',

      wages: '',
      bm_minimum_wage: '',
      ra_minimum_wage: '',
      rao_minimum_wage: '',
      rm_minimum_wage: '',
      avp_minimum_wage: '',
      vp_minimum_wage: '',
      cm_minimum_wage: '',
      embalmer_minimum_wage: '',
      coop_bm_minimum_wage: '',
      coop_gm_minimum_wage: '',
      wages_effective: '',
      active_items: [],

      search: '',
      search_items: [],
      isLoading: false,
      is_editing: false,

      name: '',
      branch: '',

      category_id: '',

      date: '',
      amount: 0,
      description: '',
      particulars: '',
      selected_region: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiPencil,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['list_of_regions', 'update_minimum_wage']),
      edit_wages(item) {
        this.is_editing = true
        this.selected_region = item
        this.wages = item.minimum_wage
        this.bm_minimum_wage = item.bm_minimum_wage
        this.ra_minimum_wage = item.ra_minimum_wage
        this.rao_minimum_wage = item.rao_minimum_wage
        this.rm_minimum_wage = item.rm_minimum_wage
        this.avp_minimum_wage = item.avp_minimum_wage
        this.vp_minimum_wage = item.vp_minimum_wage
        this.cm_minimum_wage = item.cm_minimum_wage
        this.embalmer_minimum_wage = item.embalmer_minimum_wage
        this.coop_bm_minimum_wage = item.coop_bm_minimum_wage
        this.coop_gm_minimum_wage = item.coop_gm_minimum_wage
        this.wages_effective = item.effective_date
      },
      save_edit() {
        this.update_minimum_wage({
          id: this.selected_region.id,
          minimum_wage: this.wages,
          bm_minimum_wage: this.bm_minimum_wage,
          ra_minimum_wage: this.ra_minimum_wage,
          rao_minimum_wage: this.rao_minimum_wage,
          rm_minimum_wage: this.rm_minimum_wage,
          avp_minimum_wage: this.avp_minimum_wage,
          vp_minimum_wage: this.vp_minimum_wage,
          cm_minimum_wage: this.cm_minimum_wage,
          embalmer_minimum_wage: this.embalmer_minimum_wage,
          coop_bm_minimum_wage: this.coop_bm_minimum_wage,
          coop_gm_minimum_wage: this.coop_gm_minimum_wage,
          effective_date: this.wages_effective,
        })
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            console.log(error)
          })
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      initialize_data() {
        this.list_of_regions()
          .then(response => {
            this.active_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    },
  }
</script>
